import { memo } from 'react'

import { Text } from '@cash-me/react-components'
import { useRouter } from 'next/router'

import { Layout } from 'modules/layout'
import { pushDataLayer } from 'utils/push-data-layer'

import NotFoundImg from './not-found'
import * as S from './styles'

const PageNotFound = memo(() => {
  const router = useRouter()

  pushDataLayer({
    category: 'Erro',
    action: '404 - Página indisponível',
    label: `Página: ${router.asPath}`
  })

  return (
    <Layout>
      <S.Content>
        <NotFoundImg />

        <Text as="h1" size="heading3" weight="medium">
          Desculpe essa página não está disponível
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          O link que você seguiu pode estar quebrado ou a página pode ter sido removida.
        </Text>
      </S.Content>
    </Layout>
  )
})

PageNotFound.displayName = 'PageNotFound'

export default PageNotFound
