import type { NextPage } from 'next'

import { pageNotFound } from 'data/seo'
import NotFound from 'views/page-not-found'

const PageNotFound: NextPage = () => <NotFound />

export default PageNotFound

export async function getStaticProps() {
  return {
    props: { SEO: pageNotFound }
  }
}
