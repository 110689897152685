import styled from 'styled-components'

import { theme } from 'styles/theme'

const { space } = theme

export const Content = styled.div`
  margin: ${space['spacing-giga']} auto;
  padding: 0 ${space['spacing-sm']};

  svg {
    display: block;
    margin: 0 auto ${space['spacing-xl']};
  }

  h1 {
    margin-bottom: ${space['spacing-md']};
  }
`
